import React, { useState, useContext } from "react";
import Icon, { CloseCircleOutlined, LogoutOutlined, QuestionCircleOutlined, AppstoreOutlined, MenuUnfoldOutlined, MenuFoldOutlined,LineChartOutlined, MailOutlined, UserOutlined,ApartmentOutlined,ReadOutlined,NotificationOutlined,ProfileOutlined,SendOutlined,ScheduleOutlined } from '@ant-design/icons';
import { Layout, Menu, message, Divider, Row, Col, Typography, Button, Drawer, Badge, Collapse } from 'antd';
import { Link, withRouter, useLocation, useHistory } from "react-router-dom";
import logo from "img/logo-nav.png";
import logoBianco from "img/logo-bianco.png";
import logoBG from "img/logo-bg.svg";
import { sendRequest } from 'components/utils/axios.config';
import { UserContext } from 'components/context/user-context';

import "./adminTemplate.css";

const { Text } = Typography;
const { SubMenu } = Menu;
const { Header, Sider, Content, Footer } = Layout;

function AdminTemplate(props) {


    const s_width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;

    const [collapsed, setCollaped] = useState((s_width < 768));

    let user = useContext(UserContext);

    let location = useLocation();

    let history = useHistory();

    var toggle = () => {
        setCollaped(!collapsed);
    }

    var mobileCollapse = () => {
        if (s_width < 768) setCollaped(true);
    }


    var logout = () => {

        sendRequest.get('/admin/logout').then(resp => {
            if (resp.result === 'ok') {
                localStorage.setItem("logged", 'false');
                user.updateUserContext({});
                history.push("/login");
            } else if (resp.result === 'ko') {
                message.error(resp.error);
            }
        });
    }


    return (
        <Layout>
            <img src={logoBG} id="logoBg" />
            <Sider
                trigger={null}
                collapsible
                collapsed={collapsed}
                collapsedWidth={s_width < 768 ? '0' : '80'}
                width="250"
                style={{ zIndex: 2 }}
                className="sider-menu"
            >
                <Link to="/" style={{
                    textAlign: 'center'
                }}>

                    <div className="logo" style={{ height: '64px', padding: '10px' }}>
                        <img src={logo} className="logo" />
                    </div>
                </Link>
                {s_width < 768 ? <div style={{ textAlign: 'right' }}>
                    <CloseCircleOutlined
                        className="close-menu-icon"
                        onClick={toggle}
                        style={{
                            fontSize: "20px",
                            padding: "15px",
                            color: 'white'
                        }} />
                </div> : ''}
                <Divider style={{ marginTop: 0 }} />
                <Menu mode="inline" selectedKeys={[location.pathname]}>
                    <Menu.Item key="/area-utenti">
                        <Link style={{ display: 'flex', alignItems: 'center' }} to="/area-utenti" onClick={mobileCollapse}>
                            <UserOutlined />
                            <span>Area Utenti</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/rete-agente">
                        <Link style={{ display: 'flex', alignItems: 'center' }} to="/rete-agente" onClick={mobileCollapse}>
                            <ApartmentOutlined />
                            <span>Rete Agenti</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/corsi-di-formazione">
                        <Link style={{ display: 'flex', alignItems: 'center' }} to="/corsi-di-formazione" onClick={mobileCollapse}>
                            <ReadOutlined />                            <span>Corsi di Formazione </span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/questionari-di-valutazione">
                        <Link style={{ display: 'flex', alignItems: 'center' }} to="/questionari-di-valutazione" onClick={mobileCollapse}>
                            <ProfileOutlined />                            <span>Questionari di Valutazione</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/notifiche-area-agente">
                        <Link style={{ display: 'flex', alignItems: 'center' }} to="/notifiche-area-agente" onClick={mobileCollapse}>
                            <NotificationOutlined />                            <span>Notifiche Area Agenti </span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/risorse-assegnate">
                        <Link style={{ display: 'flex', alignItems: 'center' }} to="/risorse-assegnate" onClick={mobileCollapse}>
                            <AppstoreOutlined />                            <span>Risorse Assegnate </span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/statistiche-area-agente">
                        <Link style={{ display: 'flex', alignItems: 'center' }} to="/statistiche-area-agente" onClick={mobileCollapse}>
                        <LineChartOutlined />                           <span>Statistiche Area Agenti </span>
                        </Link>
                    </Menu.Item>

                    {/* <Menu.Item key="/faq">
                        <Link to="/faq" onClick={mobileCollapse}>
                            <QuestionCircleOutlined />
                            <span>Faq</span>
                        </Link>
                    </Menu.Item> */}
                    <SubMenu
                        key="sub-faq"
                        title={
                            <Link style={{ width: "100%", display: 'block', color: "#000" }}  to="/faq" onClick={mobileCollapse}>
                                <QuestionCircleOutlined />
                                <span>Faq</span>
                            </Link>
                        }
                    >
                        <Menu.Item key="/faq">
                            <Link to="/faq" onClick={mobileCollapse}>
                                <span>Lista Faq</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="/faq/categorie-faq">
                            <Link to="/faq/categorie-faq" onClick={mobileCollapse}>
                                <span>Categorie Faq</span>
                            </Link>
                        </Menu.Item>
                    </SubMenu>
                    {/* <Menu.Item key="/ticket">
                        <Link to="/ticket" onClick={mobileCollapse}>
                            <AppstoreOutlined />
                            <span>Ticket</span>
                        </Link>
                    </Menu.Item> */}
                    <SubMenu
                        key="sub-ticket"
                        title={
                            <Link style={{ width: "100%", display: 'block', color: "#000" }} to="/ticket" onClick={mobileCollapse}>
                                <ScheduleOutlined />
                                <span>Ticket</span>
                            </Link>
                        }
                    >
                        <Menu.Item key="/ticket">
                            <Link to="/ticket" onClick={mobileCollapse}>
                                <span>Lista Ticket</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="/ticket/tipologie-ticket">
                            <Link to="/ticket/tipologie-ticket" onClick={mobileCollapse}>
                                <span>Tipologie Ticket</span>
                            </Link>
                        </Menu.Item>
                    </SubMenu>
                    {/* <Menu.Item key="/newsletter">
                        <Link to="/newsletter" onClick={mobileCollapse}>
                            <MailOutlined />
                            <span>Newsletter</span>
                        </Link>
                    </Menu.Item> */}
                    <SubMenu
                        key="sub-newsletter"
                        title={
                            <Link to="/newsletter" style={{ width: "100%", display: 'block', color: "#000" }} onClick={mobileCollapse}>
                                <MailOutlined />
                                <span>Newsletter</span>
                            </Link>
                        }
                    >
                        <Menu.Item key="/newsletter">
                            <Link to="/newsletter" onClick={mobileCollapse}>
                                <span>Lista Newsletter</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="/newsletter/inviate">
                            <Link to="/newsletter/inviate" onClick={mobileCollapse}>
                                <span>inviate</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="/newsletter/statistiche">
                            <Link to="/newsletter/statistiche" onClick={mobileCollapse}>
                                <span>Statistiche</span>
                            </Link>
                        </Menu.Item>
                    </SubMenu>
                    <Menu.Item key="/email-inviate">
                        <Link to="/email-inviate" onClick={mobileCollapse}>
                            <span>
                                <SendOutlined />
                                <span>Email inviate</span>
                            </span>
                        </Link>
                    </Menu.Item>
                </Menu>
            </Sider>
            <Layout >
                <Header>
                    <Row >
                        <Col xs={8} md={10}>
                            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                className: 'trigger',
                                onClick: toggle,
                                style: {
                                    textAlign: 'left',
                                    fontSize: "20px",
                                    padding: "0 15px"
                                }
                            })}
                        </Col>
                        <Col xs={8} md={0}>
                            <Link to="/"><img src={logo} className="logo" /></Link>
                        </Col>
                        <Col xs={4} md={10}>
                        </Col>
                        <Col xs={4} md={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>

                            <a onClick={logout} className="logout" style={{ textAlign: 'right', color: 'inherit' }}>
                                {!(s_width < 768) ? <span>LOGOUT</span> : ""}
                                <LogoutOutlined
                                    style={{
                                        fontSize: "20px",
                                        padding: "0 15px 0 5px",
                                        verticalAlign: 'sub'
                                    }} />
                            </a>
                        </Col>
                    </Row>



                </Header>
                <Content
                    style={{
                        margin: '15px 15px 0px',
                        minHeight: "calc(100vh - 148px)"
                    }}
                    className="container-content"
                    id="main-content"
                >
                    {props.children}
                </Content>
                <Footer style={{ width: '100%', background: '#f7f7f7', textAlign: 'center' }}>

                    <span style={{ padding: '10px 20px', fontSize: 11 }}>Engineering and design by <a href="https://www.sernicola-labs.com/" target="_blank">Sernicola Labs Srl</a></span>

                </Footer>
            </Layout>
        </Layout>
    );
}


export default AdminTemplate;
import React, { Suspense, useEffect, lazy, useState } from 'react';
import { Spin, Row, ConfigProvider, message, Skeleton } from 'antd';
import itIT from 'antd/es/locale/it_IT';
import { Switch, Route, Redirect } from "react-router-dom";
import { AdminTemplate } from "./components/template";
import { sendRequest } from 'components/utils/axios.config';
import { UserContext } from 'components/context/user-context';
import Lottie from 'react-lottie';
import animationData from 'img/logo.json';

import "antd/dist/antd.less";
import "./index.css";
import { empty } from 'components/utils/utils';
const Login = lazy(() => import('./views/login'));
const Home = lazy(() => import('./views/home'));
const ReteAgente = lazy(() => import('./views/rete-agente'));
const RisorseAssegnate = lazy(() => import('./views/risorse-assegnate'));
const QuestionariDiValutazione = lazy(() => import('./views/questionari-di-valutazione'));
const CorsiDiFormazione = lazy(() => import('./views/corsi-di-formazione'));
const AreaUtenti = lazy(() => import('./views/area-utenti'));
const NotificheAreaAgente = lazy(() => import('./views/notifiche-area-agente'));
const StatisticheAreaAgente = lazy(() => import('./views/statistiche-area-agente'));
const Faq = lazy(() => import('./views/faq'));
const CategorieFaq = lazy(() => import('./views/categorie-faq'));
const Ticket = lazy(() => import('./views/ticket'));
const TipologieTicket = lazy(() => import('./views/tipologie-ticket'));
const Newsletter = lazy(() => import('./views/newsletter'));
const NewsletterInviate = lazy(() => import('./views/newsletter-inviate'));
const NewsletterStatistiche = lazy(() => import('./views/newsletter-statistiche'));
const EmailInviate = lazy(() => import('./views/email-inviate'));


var first_render = true;

const DelayedSpinner = () => {
    const [showSpinner, setShowSpinner] = useState(false);
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => setShowSpinner(true), 600);

        return () => clearTimeout(timer);
    });

    return showSpinner && <div
        id="caricamento-globale">
        <Lottie options={defaultOptions}
            height={115}
            width={88}
        />
    </div>;
};

function Routes() {

    if (localStorage.getItem("logged") == undefined) {
        localStorage.setItem("logged", false);
    }

    var [activeRequests, setActiveRequests] = useState(0);
    var [user_info, setUserInfo] = useState({});

    const getUserInfo = async () => {
        let response = await sendRequest.get('/admin/info');
        setUserInfo(response);
    }

    if (first_render) {
        /**
         * impostazioni axios
         */
        // Add a request interceptor
        sendRequest.interceptors.request.use(function (config) {
            // Do something before request is sent
            setActiveRequests(activeRequests + 1);
            return config;
        }, function (error) {
            // Do something with request error
            setActiveRequests(activeRequests - 1);
            return Promise.reject(error);
        });
        // Add a response interceptor
        sendRequest.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            // console.log(response);
            setActiveRequests(activeRequests - 1);
            return response.data;
        }, function (error) {

            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            if (error.response.status === 403) {
                message.error('Non hai i permessi per accedere a questa risorsa');
                localStorage.setItem("logged", 'false');
            }
            if (error.response.status === 404) {
                message.error('404 Url not found');
            }
            if (error.response.status === 400) {
                message.error(error.response.data ? error.response.data : 'Errore durante la richiesta');
            }
            setActiveRequests(activeRequests - 1);
            return Promise.reject(error);
        });
        /**
         * fine impostazioni axios
         */

        if (localStorage.getItem("logged") === 'true') {
            getUserInfo();
        }

        first_render = false;
    }



    return (
        <div >
            <ConfigProvider locale={itIT}>
                <UserContext.Provider value={{ info: user_info, updateUserContext: setUserInfo }}>
                    {localStorage.getItem("logged") !== 'true' ?
                        <Suspense
                            fallback={
                                <Row type="flex" justify="space-around" align="middle" style={{ minHeight: "calc(100vh - 112px)" }}>
                                    <Spin />
                                </Row>
                            }
                        >
                            <Switch>
                                <Route exact path="/login">
                                    <Login />
                                </Route>
                                <Route path="/">
                                    <Redirect to="/login" />
                                </Route>
                            </Switch>
                        </Suspense>
                        :
                        (empty(user_info) ? null : <AdminTemplate>
                            <Suspense fallback={<Skeleton active />}>
                                <Switch>
                                    <Route exact path="/">
                                        <Home />
                                    </Route>
                                    <Route exact path="/rete-agente">
                                        <ReteAgente />
                                    </Route>
                                    <Route exact path="/risorse-assegnate">
                                        <RisorseAssegnate />
                                    </Route>
                                    <Route exact path="/questionari-di-valutazione">
                                        <QuestionariDiValutazione />
                                    </Route>
                                    <Route exact path="/corsi-di-formazione">
                                        <CorsiDiFormazione />
                                    </Route>
                                    <Route exact path="/area-utenti">
                                        <AreaUtenti />
                                    </Route>
                                    <Route exact path="/notifiche-area-agente">
                                        <NotificheAreaAgente />
                                    </Route>
                                    <Route exact path="/statistiche-area-agente">
                                        <StatisticheAreaAgente />
                                    </Route> 
                                    <Route exact path="/faq">
                                        <Faq />
                                    </Route>
                                    <Route exact path="/faq/categorie-faq">
                                        <CategorieFaq />
                                    </Route>
                                    <Route exact path="/ticket">
                                        <Ticket />
                                    </Route>
                                    <Route exact path="/ticket/tipologie-ticket">
                                        <TipologieTicket />
                                    </Route>
                                    <Route exact path="/newsletter">
                                        <Newsletter />
                                    </Route>
                                    <Route exact path="/newsletter/inviate">
                                        <NewsletterInviate />
                                    </Route>
                                    <Route exact path="/newsletter/statistiche">
                                        <NewsletterStatistiche />
                                    </Route>
                                    <Route exact path="/email-inviate">
                                        <EmailInviate />
                                    </Route>
                                </Switch>
                            </Suspense>
                        </AdminTemplate>)
                    }
                </UserContext.Provider>
            </ConfigProvider>
            {activeRequests > 0 && <DelayedSpinner />}
        </div>
    );
}


export default Routes;
